import React from "react";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { OutboundLink } from "gatsby-plugin-google-gtag";

interface TestimonailProps {
  name: string;
  username: string;
  userImage: any;
  userTwitterUrl: string;
  testimonialText: string;
  externalUrl?: string;
}

const Testimonial = (props: TestimonailProps) => {
  const content = (
    <TestimonialContent
      name={props.name}
      username={props.username}
      userImage={props.userImage}
      testimonialText={props.testimonialText}
      externalUrl={props.externalUrl}
      userTwitterUrl={props.userTwitterUrl}
    />
  );

  return props.externalUrl ? (
    <OutboundLink href={props.externalUrl} target="__blank">
      {content}
    </OutboundLink>
  ) : (
    <div>{content}</div>
  );
};

function TestimonialContent(props: TestimonailProps) {
  const userImage: IGatsbyImageData | undefined = getImage(
    props.userImage?.childImageSharp?.gatsbyImageData
  );
  return (
    <div className="rounded-xl overflow-hidden p-5 bg-white hover:opacity-80">
      <OutboundLink href={props.userTwitterUrl} target="__blank">
        <div className="flex flex-row hover:cursor-pointer">
          <div className="flex justify-center items-center">
            <div className="w-12 h-12 rounded-full">
              <GatsbyImage
                image={userImage as IGatsbyImageData}
                className="rounded-full"
                alt={props.name}
              />
            </div>
          </div>
          <div className="flex flex-col ml-2 flex-grow font-inter">
            <div className="text-text font-medium text-lg">{props.name}</div>
            <div className="text-gray-600 font-light">{props.username}</div>
          </div>
        </div>
      </OutboundLink>
      <div className="text-text pt-4 font-inter text-lg text-justify">
        {props.testimonialText}
      </div>
    </div>
  );
}

export default Testimonial;
