import { Link } from "gatsby";
import React, { useState } from "react";
import Layout from "../components/core/Layout";
import ArticleCardV2 from "./ArticleCardV2";
import { StaticImage } from "gatsby-plugin-image";
import Testimonial from "./Testimonial";
import NewsletterRow from "../components/newsletter/NewsletterRow";
import { Link as ReactScrollLink } from "react-scroll";
require("prismjs/themes/prism-tomorrow.css");

interface NewslettersProps {
  pageContext: {
    newsletters: any;
    testimonials: any;
  };
}

export default function Newsletters(props: NewslettersProps) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [successfullySubscribed, setSuccessfullySubscribed] = useState(false);

  function validEmail(email: string): boolean {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email.length === 0 || re.test(String(email).toLowerCase());
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (window && !window.location.href.includes("localhost")) {
      window.gtag(`event`, `click`, {
        event_category: `engagement`,
        event_label: "newsletter_subscribe",
      });
    }
    try {
      const currentUrl = window.location.href;
      if (email.length && validEmail(email)) {
        setLoading(true);
        const formData = {
          email: email,
          referringSite: currentUrl,
          firstName: "", // You can add logic to get the first name if needed
          lastName: "", // You can add logic to get the last name if needed
        };
        const response = await fetch("/api/subscribe", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
        const result = await response.json();
        console.log("result", result);
        if (
          result &&
          result.data &&
          (result.data.status === "validating" ||
            result.data.status === "active")
        ) {
          setSuccessfullySubscribed(true);
          setTimeout(() => {
            // Handle success (e.g., show a success message)
            setLoading(false);
          }, 1000);
        } else {
          // Handle error (e.g., show an error message)
          setSuccessfullySubscribed(false);
          setLoading(false);
        }
      }
    } catch (error) {
      // Handle error (e.g., show an error message)
      setLoading(false);
      setSuccessfullySubscribed(false);
    }
  };

  const [screenSize, setScreenSize] = React.useState("lg");
  const inlineDispatchLogoClass =
    screenSize === "lg"
      ? "w-36 translate-y-1"
      : screenSize === "md"
      ? "w-32"
      : "w-32";

  // Detect screen size
  React.useEffect(() => {
    const checkScreenSize = () => {
      const width = window.innerWidth;
      if (width >= 1024) {
        setScreenSize("lg");
      } else if (width >= 768) {
        setScreenSize("md");
      } else {
        setScreenSize("sm");
      }
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  return (
    <>
      <Layout
        maxWidth="xl"
        pageTitle="JetpackCompose.app's Dispatch: Get Smart About Android & Jetpack Compose"
        pageDescription="Keeping up with the Android Development landscape can be hard. Dispatch makes it easy, entertaining and rewarding. It's a newsletter that delivers just the bytes that matter."
        pageSlug="/newsletter"
        pageImageUrl="/newsletter_poster.png"
        seoImageUrl="/newsletter_poster.png"
      >
        <div className="mx-6 my-16 flex flex-col justify-center items-center">
          <div className="lg:w-3/5 w-full">
            <div className="mb-4 flex justify-center items-center">
              <StaticImage
                src="../images/newsletter/dispatch-simple-logo.png"
                height={175}
                alt={"Dispatch Logo"}
                placeholder="blurred"
              />
            </div>
            <h1 className="font-inter lg:text-7xl text-5xl font-light text-center">
              Get Smart About{" "}
              <span className="font-bold font-inter">Android</span> &{" "}
              <span className="font-bold font-inter">Jetpack Compose</span>
            </h1>
            <div className="font-nunitoSans text-2xl my-8 text-center">
              Keeping up with the Android Development landscape can be hard.
              Dispatch makes it easy, entertaining and rewarding. It's a
              newsletter that delivers just the bytes that matter.
            </div>

            <form
              className="mx-auto mt-10 flex max-w-md"
              onSubmit={handleSubmit}
            >
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="min-w-0 flex-auto rounded-tl-md rounded-bl-md bg-white/5 px-3.5 py-2 text-text text-sm sm:text-sm sm:leading-6 ring-1 font-inter placeholder-accent border-accent ring-accent"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <button
                type="submit"
                className="flex-none rounded-tr-md rounded-br-md bg-accent px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white font-inter ring-1 ring-accent"
                disabled={loading || successfullySubscribed}
              >
                {successfullySubscribed
                  ? "SUBSCRIBED!!"
                  : loading
                  ? "SUBSCRIBING..."
                  : "SUBSCRIBE"}
              </button>
            </form>

            <ReactScrollLink
              to="latestIssues"
              smooth={true}
              duration={3000}
              offset={-70} // Optional: offset for fixed headers
              onClick={() => {
                if (window && !window.location.href.includes("localhost")) {
                  window.gtag(`event`, `click`, {
                    event_category: `engagement`,
                    event_label: "newsletter_see_latest_issues",
                  });
                }
              }}
            >
              <div className="mt-4 mb-4 text-center text-lg text-text font-inter underline hover:cursor-pointer font-medium">
                {" "}
                See the latest issues
              </div>
            </ReactScrollLink>

            <div className="mt-14 mb-4 text-center text-lg text-gray-700 font-inter">
              {" "}
              Read by Top Android Engineers at
            </div>
            <div className="flex flex-wrap justify-center items-center">
              <div className="m-2">
                <StaticImage
                  src="../images/google-logo.png"
                  alt="Google"
                  placeholder="blurred"
                  width={150}
                />
              </div>
              <div className="m-2">
                <StaticImage
                  src="../images/airbnb-logo.png"
                  alt="Airbnb"
                  placeholder="blurred"
                  width={150}
                />
              </div>
              <div className="m-2">
                <StaticImage
                  src="../images/meta-logo.png"
                  alt="Meta"
                  placeholder="blurred"
                  width={150}
                />
              </div>
              <div className="m-2">
                <StaticImage
                  src="../images/square-logo.png"
                  alt="Square"
                  placeholder="blurred"
                  width={150}
                />
              </div>
              <div className="m-2">
                <StaticImage
                  src="../images/microsoft-logo.png"
                  alt="Microsoft"
                  placeholder="blurred"
                  width={150}
                />
              </div>
              <div className="m-2">
                <StaticImage
                  src="../images/twitter-logo.png"
                  alt="Twitter"
                  placeholder="blurred"
                  width={150}
                />
              </div>
              <div className="m-2">
                <StaticImage
                  src="../images/reddit-logo.png"
                  alt="Reddit"
                  placeholder="blurred"
                  width={150}
                />
              </div>
              <div className="m-2">
                <StaticImage
                  src="../images/instacart-logo.png"
                  alt="Instacart"
                  placeholder="blurred"
                  width={175}
                />
              </div>

              <div className="m-2">
                <StaticImage
                  src="../images/jetbrains-logo.png"
                  alt="JetBrains"
                  placeholder="blurred"
                  width={70}
                />
              </div>

              <div className="m-2">
                <StaticImage
                  src="../images/netflix-logo.png"
                  alt="Netflix"
                  placeholder="blurred"
                  width={125}
                />
              </div>

              <div className="m-2">
                <StaticImage
                  src="../images/tiktok-logo.png"
                  alt="TikTok"
                  placeholder="blurred"
                  width={175}
                />
              </div>

              <div className="m-2">
                <StaticImage
                  src="../images/disney-hotstar-logo.png"
                  alt="Disney Hotstar"
                  placeholder="blurred"
                  width={140}
                />
              </div>

              <div className="m-2">
                <StaticImage
                  src="../images/dropbox-logo.png"
                  alt="Dropbox"
                  placeholder="blurred"
                  width={175}
                />
              </div>
              <div className="m-2">
                <StaticImage
                  src="../images/clubhouse-logo.webp"
                  alt="Clubhouse"
                  placeholder="blurred"
                  width={175}
                />
              </div>
              <div className="m-2">
                <StaticImage
                  src="../images/turo-logo.png"
                  alt="Turo"
                  placeholder="blurred"
                  width={100}
                />
              </div>
              <div className="m-2">
                <StaticImage
                  src="../images/soundcloud-logo.png"
                  alt="Soundcloud"
                  placeholder="blurred"
                  width={100}
                />
              </div>
              <div className="m-2">
                <StaticImage
                  src="../images/american-express-logo.png"
                  alt="American Express"
                  placeholder="blurred"
                  width={125}
                />
              </div>
              <div className="m-2">
                <StaticImage
                  src="../images/phone-pe-logo.png"
                  alt="Phone Pe"
                  placeholder="blurred"
                  width={150}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="px-6 pt-6 pb-24 w-full bg-[#F4F2EC]">
          <div className="flex justify-center items-center">
            <div className="lg:w-4/5 w-full flex flex-col">
              <h2 className="mt-8 mb-16 font-nunitoSans lg:text-4xl text-2xl text-text text-center">
                What Our Brilliant Subscribers Think (We Didn’t Pay Them!)
              </h2>
              <div className="w-full lg:columns-3 md:columns-2 columns-1 gap-4 space-y-4">
                {props.pageContext.testimonials.map((testimonial) => {
                  return (
                    <Testimonial
                      name={testimonial.name}
                      username={testimonial.username}
                      testimonialText={testimonial.testimonial}
                      userImage={testimonial.avatarImage}
                      userTwitterUrl={testimonial.twitterUrl}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="px-6 pt-6 pb-12 w-full bg-[#F2FFE2]">
          <div className="flex justify-center items-center">
            <div className="lg:w-1/2 md:w-3/4 sm:w-5/6 w-full flex flex-col">
              <h2 className="mt-8 mb-16 font-playfairDisplay lg:text-7xl md:text-5xl text-4xl text-text text-center">
                What makes Dispatch unique?
              </h2>
              <div className="lg:text-3xl md:text-3xl text-xl text-text antialiased text-justify tracking-widest">
                <p>
                  <span className="lg:text-6xl md:text-6xl text-4xl italic font-semibold">
                    In
                  </span>{" "}
                  a sea of newsletters that merely aggregate links,{" "}
                  <StaticImage
                    src="../images/newsletter/dispatch-simple-logo-dark.png"
                    alt="Dispatch"
                    placeholder="blurred"
                    className={inlineDispatchLogoClass}
                  ></StaticImage>{" "}
                  tries to stand out. Let’s be honest—most of us don’t have the
                  time to sift through endless links, hoping to find the golden
                  nuggets. We want concise, valuable insights delivered straight
                  to our inbox, and we want it fast. Dispatch is here to fill
                  that gap.
                </p>
                <br />
                <p>
                  Here’s how Dispatch{" "}
                  <span className="italic decoration-coral underline decoration-wavy underline-offset-8 font-playfairDisplay">
                    differentiates
                  </span>{" "}
                  itself— <br />
                  <br />
                  <ul className="list-none list-inside">
                    <li>
                      <b className="text-white bg-text font-inter rounded-xl p-2">
                        Golden Nuggets in 5 Minutes or Less
                      </b>{" "}
                      <div className="mt-4">
                        🧠Each issue is packed with the most valuable insights
                        and tips, all delivered in a bite-sized format that
                        respects your time.
                      </div>
                    </li>
                    <br />
                    <li>
                      <b className="text-white bg-text font-inter rounded-xl p-2">
                        Entertaining and Insightful
                      </b>
                      <div className="mt-4">
                        {" "}
                        🤡Learning should be fun. Dispatch is not just
                        informative but also engaging and humorous, making it a
                        delight to read.
                      </div>
                    </li>
                    <br />
                    <li>
                      <b className="text-white bg-text font-inter rounded-xl p-2">
                        Curated for Quality
                      </b>
                      <div className="mt-4">
                        {" "}
                        📋No more endless link lists. Each issue is
                        painstakingly curated to ensure you get only the most
                        relevant bits. We do the hard work so you can focus on
                        what matters.
                      </div>
                    </li>
                    <br />
                    <li>
                      <b className="text-white bg-text font-inter rounded-xl p-2">
                        Absolutely Free
                      </b>
                      <div className="mt-4">
                        {" "}
                        🆓 No cost to the readers. Dispatch is completely free,
                        ensuring that top-notch content is accessible to
                        everyone.
                      </div>
                    </li>
                    <br />
                  </ul>
                </p>
                <br />
                Subscribe to Dispatch today and change the way you stay updated
                with the latest in Jetpack Compose and Android development.
                There's a good reason why our readers look forward to every
                issue.
              </div>
              <br />
              <br />
              <br />
              <NewsletterRow
                tagline={
                  <>
                    Think of us as your Android barista – we know your order,
                    and yes, we add the extra shot of clarity
                  </>
                }
                invertColors={true}
              />
            </div>
          </div>
        </div>

        <div className="mx-6 mt-14 mb-24">
          <div className="flex justify-center items-center">
            <div className="lg:w-4/5 w-full flex flex-col">
              <h2
                id="latestIssues"
                className="mb-16 font-nunitoSans lg:text-4xl md:text-4xl text-2xl text-text text-center"
              >
                Enough talk, we prefer to show you the goods
              </h2>
              <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-4">
                {props.pageContext.newsletters.map((newsletter) => {
                  return (
                    <Link
                      to={newsletter.frontmatter.slug}
                      partiallyActive={true}
                      onClick={() => {
                        // gtag isn't available in localhost so using this workaround
                        if (
                          window &&
                          !window.location.href.includes("localhost")
                        ) {
                          window.gtag(`event`, `click`, {
                            event_category: `engagement`,
                            event_label: newsletter.frontmatter.slug,
                          });
                        }
                      }}
                    >
                      <ArticleCardV2
                        title={newsletter.frontmatter.title}
                        description={newsletter.frontmatter.description}
                        dynamicImage={newsletter.frontmatter.heroImageUrl}
                        date={newsletter.frontmatter.date}
                      />
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
